
import { defineComponent } from 'vue';
import { useInject } from '@/utils/inject';
import { useGetters } from '@/store/mixin';
import SearchTable from '@/components/table/SearchTable.vue';

export default defineComponent({
  name: 'WithdrawRecord',
  components: { SearchTable },
  setup() {
    const { https } = useInject();
    const { userMeta, isAgent } = useGetters();

    const tableOpts = {
      search: {
        criteria: {
          cols: [
            {
              field: 'orderCode',
              label: '订单号'
            },
            {
              field: 'userId',
              label: '代理信息',
              type: 'agent',
              visible: () => !isAgent,
              defVal: () => (isAgent ? userMeta.uid : '')
            }
          ]
        },
        remote: {
          action: https?.payment.pageWithdraw
        }
      },
      cols: [
        {
          field: 'orderCode',
          label: '订单号',
          ability: 'copy',
          width: 250
        },
        {
          field: 'amount',
          label: '提现金额',
          width: 120
        },
        {
          field: 'settleAmt',
          label: '到账金额',
          width: 120
        },
        {
          field: 'rate',
          label: '费率',
          width: 100
        },
        {
          field: 'extra',
          label: '额外手续费',
          width: 100
        },
        {
          field: 'finalFee',
          label: '总手续费',
          width: 100
        },
        {
          field: 'statusDesc',
          label: '订单状态',
          width: 100
        },
        {
          field: 'realName',
          label: '用户名',
          width: 100
        },
        {
          field: 'userPhone',
          label: '用户手机号'
        },
        {
          field: 'remark',
          label: '备注'
        },
        {
          field: 'createTime',
          label: '创建时间'
        },
        {
          field: 'updateTime',
          label: '更新时间'
        },

      ]
    };
    return { tableOpts };
  }
});
